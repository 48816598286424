import React from "react";
import Layout from "components/Layout";

function NotFound() {
  return (
    <Layout>
      <div>404 page</div>
    </Layout>
  );
}

export default NotFound;
